import * as React from "react"
import { useState } from "react"
import cx from "classnames"
import { FiX, FiMenu } from "react-icons/fi"
import { Link } from "gatsby"

import { NAVIGATION_LINKS } from "./config"

const Header = () => {
  const [menu, setMenu] = useState(false)

  return (
    <header className="header-area formobile-menu header--transparent color-white">
      <div
        className={cx("header-wrapper", {
          "menu-open": menu,
        })}
        id="header-wrapper"
      >
        <div className="header-left">
          <div className="logo">
            <Link to="/">
              <img src="/images/logo.png" />
              Kamil Cyba
            </Link>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              {NAVIGATION_LINKS.map(({ to, text }) => (
                <li key={to}>
                  <Link to={to}>{text}</Link>
                </li>
              ))}
            </ul>
          </nav>

          {menu ? (
            <div className="close-menu d-block d-lg-none">
              <span onClick={() => setMenu(false)} className="closeTrigger">
                <FiX />
              </span>
            </div>
          ) : (
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={() => setMenu(true)}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
