export const NAVIGATION_LINKS = [
  {
    to: "/o-mnie",
    text: "O mnie",
  },
  {
    to: "/oferta",
    text: "Oferta",
  },
  {
    to: "/blog",
    text: "Blog",
  },
]
