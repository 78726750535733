import * as React from "react"
import BlogPost from "../BlogPost"

type Post = {
  node: {
    frontmatter: {
      title: string
      description: string
      category: string
      date: string
      thumbnail: string
    }
    fields: {
      slug: string
    }
  }
}

type Props = {
  posts: Post[]
}

const BlogPostsList = ({ posts }: Props) => (
  <div className="row">
    {posts.map(({ node }) => (
      <BlogPost
        title={node.frontmatter.title}
        category={node.frontmatter.category}
        thumbnail={node.frontmatter.thumbnail}
        slug={node.fields.slug}
      />
    ))}
  </div>
)

export default BlogPostsList
