import * as React from "react"

type Props = {
  title: string
  subtitle: string
  image: string
}

const SplashScreen = ({ title, subtitle, image }: Props) => (
  <div className="slider-activation">
    <div
      className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image"
      style={{ backgroundImage: `url(${image})` }}
      data-black-overlay="6"
    >
      <div className="container position-relative align-items-center">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner">
              <h1
                className="title theme-gradient"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <h3 dangerouslySetInnerHTML={{ __html: subtitle }} />
            </div>
          </div>
        </div>
        <div className="service-wrapper service-white">
          {/* <ServiceOne /> */}
        </div>
      </div>
    </div>
  </div>
)

export default SplashScreen
