import * as React from "react"
import * as FiIcons from "react-icons/fi"
import { Link } from "gatsby"
import slugify from "slugify"

type Service = {
  homePage: {
    description: string
    icon: string
  }
  title: string
}

type Props = {
  services: Service[]
  title: string
  content: string
  image: string
}

const Services = ({ image, title, content, services }: Props) => (
  <div className="services-home row">
    <div className="col-lg-4 col-12">
      <div className="row">
        <div className="col-12">
          <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
            <h2 className="title">{title}</h2>
            <p dangerouslySetInnerHTML={{ __html: content }} />
            <div className="service-btn">
              <Link className="btn-transparent rn-btn-dark" to="/oferta">
                <span className="text">Szczegóły</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="services-home__photo thumbnail">
            <img className="w-100" src={image} alt="About Images" />
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-8 col-12 mt_md--50">
      <div className="row service-one-wrapper">
        {services.map(({ title, homePage }) => (
          <div
            className="col-lg-6 col-md-6 col-sm-6 col-12"
            key={slugify(title)}
          >
            <Link to={`/oferta#${slugify(title)}`}>
              <div className="service service__style--2">
                <div className="icon">{serviceIcon(homePage.icon)}</div>
                <div className="content">
                  <h3 className="title">{title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: homePage.description,
                    }}
                  />
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>
)

const serviceIcon = iconName => {
  const Icon = FiIcons[iconName]

  if (!Icon) return null

  return <Icon />
}

export default Services
