import * as React from "react"
import BlogPost from "../../BlogPost"

type Post = {
  title: string
  category: string
}

type Props = {
  posts: Post[]
  title: string
  content: string
}

const RecentBlogPosts: React.FC<Props> = ({ title, content, posts }) => (
  <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30 brand-separation">
    <div className="container">
      <div className="row align-items-end">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="section-title text-left">
            <h2>{title}</h2>
            <p>{content}</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
            <a className="btn-transparent rn-btn-dark" href="/blog">
              <span className="text">Zobacz wszystkie</span>
            </a>
          </div>
        </div>
      </div>
      <div className="row mt--60 mt_sm--40">
        {posts.map(({ node }) => (
          <BlogPost
            key={node.id}
            slug={node.fields.slug}
            category={node.frontmatter.category}
            title={node.frontmatter.title}
            thumbnail={node.frontmatter.thumbnail}
          />
        ))}
      </div>
    </div>
  </div>
)

export default RecentBlogPosts
