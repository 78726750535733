import * as React from "react"
import CtaWidget from "./CtaWidget"
import Navigation from "./Navigation"
import ContactInformations from "./ContactInformations"

type Props = {
  withCta?: boolean
  cta: {
    firstLine: string
    secondLine: string
    button: string
  }
}

const Footer = ({ contact, cta, withCta }: Props) => (
  <footer className="footer-area footer-default">
    <div className="footer-wrapper">
      <div className="row align-items-end row--0">
        <div className="col-lg-6">
          {withCta && (
            <CtaWidget
              heading={cta.firstLine}
              title={cta.secondLine}
              buttonText={cta.button}
            />
          )}
        </div>

        <div className="col-lg-6">
          <div className="footer-right" data-black-overlay="6">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-12">
                <Navigation />
              </div>

              <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                <ContactInformations
                  email={contact.email}
                  fb={contact.fb}
                  ig={contact.ig}
                />
              </div>

              <div className="col-lg-12">
                <div className="copyright-text">
                  <p>Copyright © 2020 Kamil Cyba. All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
