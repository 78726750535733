import * as React from "react"
import { Link } from "gatsby"
import { NAVIGATION_LINKS } from "./config"

const Navigation = () => (
  <div className="footer-link">
    <h4>Nawigacja</h4>
    <ul className="ft-link">
      {NAVIGATION_LINKS.map(({ to, text }) => (
        <li key={to}>
          <Link to={to}>{text}</Link>
        </li>
      ))}
    </ul>
  </div>
)

export default Navigation
