import * as React from "react"
import cx from "classnames"
import marked from "marked"
import { Link } from "gatsby"
import slugify from "slugify"

type Props = {
  title: string
  content: string
  image: string
  ctaButtonText: string
  imageSide: "left" | "right"
  brandSeparation?: boolean
}

const ServiceDetails = ({
  title,
  content,
  image,
  imageSide,
  ctaButtonText,
  brandSeparation = false,
}: Props) => {
  const renderContent = () => (
    <div className="details mt_md--30 mt_sm--30">
      <h4 className="title">{title}</h4>
      <div dangerouslySetInnerHTML={{ __html: marked(content) }} />

      <Link to="/kontakt#formularz" className="rn-button-style--2 btn-solid">
        {ctaButtonText}
      </Link>
    </div>
  )

  const renderImage = () => (
    <div className="thumb">
      <img className="w-100" src={image} alt="Service Images" />
    </div>
  )

  return (
    <div
      className={cx("rn-service-details ptb--120", {
        "bg_color--1": imageSide === "left",
        "brand-separation": brandSeparation && imageSide === "left",
      })}
      id={slugify(title)}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="service-details-inner">
              <div className="inner">
                <div className="row sercice-details-content pb--80 align-items-center">
                  <div
                    className={cx("col-lg-6 col-12 order-md-1", {
                      "order-2": imageSide === "right",
                    })}
                  >
                    {imageSide === "left" ? renderImage() : renderContent()}
                  </div>
                  <div
                    className={cx("col-lg-6 col-12 order-md-2", {
                      "order-1": imageSide === "right",
                    })}
                  >
                    {imageSide === "left" ? renderContent() : renderImage()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceDetails
