import * as React from "react"
import { Link } from "gatsby"
import { isMobile } from "react-device-detect"

type Props = {
  parent?: string
  title: string
  background: string
  backgroundMobile?: string
}

const Breadcrumb = ({ parent, title, background, backgroundMobile }: Props) => {
  const finalBackground =
    isMobile && backgroundMobile !== "" ? backgroundMobile : background

  return (
    <div
      className="breadcrumb-area rn-bg-color bg_image"
      style={{ backgroundImage: `url(${finalBackground})` }}
      data-black-overlay="6"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner pt--100">
              <h2 className="title">{title}</h2>
              <ul className="page-list">
                <li className="breadcrumb-item">
                  <Link to="/">Strona główna</Link>
                </li>
                {parent ? <li className="breadcrumb-item">{parent}</li> : ""}
                <li className="breadcrumb-item active">{title}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
