import * as React from "react"
import Slider from "react-slick"

const SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  autoplay: false,
  responsive: [
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

type Transformation = {
  image: string
}

type Props = {
  items: Transformation[]
}

const Transformations = ({ items }: Props) => (
  <div className="slider slider--transformations slick-space-gutter--15">
    <Slider {...SLIDER_SETTINGS}>
      {items.map(({ image }, index) => (
        <div className="transformations-slider__slide" key={index}>
          <img className="transformations-slider__slide__image" src={image} />
        </div>
      ))}
    </Slider>
  </div>
)

export default Transformations
