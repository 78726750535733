import * as React from "react"
import { Link } from "gatsby"
import slugify from "slugify"

type Props = {
  title: string
  category: string
  thumbnail: string
  slug: string
}

const BlogPost: React.FC<Props> = ({ title, category, thumbnail, slug }) => {
  const postLink = `/blog/${slugify(title, {
    remove: /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/g,
  })}`

  return (
    <div className="col-lg-4 col-md-6 col-12">
      <div className="blog blog-style--1">
        <div className="thumbnail">
          <Link to={postLink}>
            <img className="w-100" src={thumbnail} alt={`${title} image`} />
          </Link>
        </div>
        <div className="content">
          <p className="blogtype">{category}</p>
          <h4 className="title">
            <Link to={postLink}>{title}</Link>
          </h4>
          <div className="blog-btn">
            <Link className="rn-btn text-white" to={postLink}>
              Czytaj więcej
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPost
