import * as React from "react"

type Props = {
  title: string
  content: string
  image: string
}

const ContactForm = ({ title, content, image }: Props) => {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [message, setMessage] = React.useState("")

  return (
    <div className="contact-form">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">{title}</h2>
              <p className="description">{content}</p>
            </div>
            <div className="form-wrapper">
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action="/kontakt/sukces"
              >
                <input type="hidden" name="bot-field" />
                <label htmlFor="name">
                  <input
                    id="name"
                    type="text"
                    name="subject"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Twoje imię*"
                  />
                </label>

                <label htmlFor="email">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Twój adres email*"
                  />
                </label>

                <label htmlFor="message">
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    placeholder="Wiadomość*"
                  />
                </label>

                <button className="rn-button-style--2 btn-solid" type="submit">
                  Wyślij
                </button>
              </form>
            </div>
          </div>

          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <img src={image} alt="zdjęcie kontaktowe" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
