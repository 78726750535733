import * as React from "react"
import { FaInstagram, FaFacebookF } from "react-icons/fa"

type Props = {
  email: string
  fb: string
  ig: string
}

const ContactInformations = ({ email, fb, ig }: Props) => {
  const SocialShare = [
    { Social: <FaFacebookF />, link: fb },
    { Social: <FaInstagram />, link: ig },
  ]

  return (
    <div className="footer-link">
      <h4>Porozmawiajmy</h4>
      <ul className="ft-link">
        <li>
          <a href={`mailto:${email}`}>{email}</a>
        </li>
      </ul>

      <div className="social-share-inner">
        <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
          {SocialShare.map((val, i) => (
            <li key={i}>
              <a href={`${val.link}`} target="_blank">
                {val.Social}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ContactInformations
