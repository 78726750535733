import * as React from "react"
import GoogleMapReact from "google-map-react"

type Props = {
  lat: number
  lng: number
  zoom?: number
}

const GoogleMap: React.FC<Props> = ({ lat, lng, zoom = 16 }) => (
  <GoogleMapReact
    bootstrapURLKeys={{
      key: process.env.GATSBY_GOOGLE_API_KEY,
      libraries: ["places"],
    }}
    defaultCenter={{ lat, lng }}
    defaultZoom={zoom}
  >
    <MapPointer lat={lat} lng={lng} />
  </GoogleMapReact>
)

const MapPointer = () => (
  <>
    <div className="map-pointer" />
    <div className="map-pointer__pulse" />
  </>
)

export default GoogleMap
