import * as React from "react"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"

import { Header } from "../Header"
import { Footer } from "../Footer"
import { graphql, useStaticQuery } from "gatsby"
import Seo from "../Seo"

type Props = {
  footerWithCta?: boolean
  children: React.ReactNode
}

const Layout = ({ footerWithCta = true, children }: Props) => {
  const {
    data: { frontmatter: data },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      data: markdownRemark(
        fields: { collection: { eq: "pages" }, slug: { eq: "shared" } }
      ) {
        frontmatter {
          logo
          cta {
            firstLine
            secondLine
            button
          }
          contact {
            email
            fb
            ig
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <Seo /> */}

      <Header />

      <main className="page-wrapper">{children}</main>

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <Footer withCta={footerWithCta} cta={data.cta} contact={data.contact} />
    </>
  )
}

export default Layout
