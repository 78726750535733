import * as React from "react"
import { Link } from "gatsby"

type Section = {
  title: string
  content: string
}

type Props = {
  title: string
  content: string
  image: string
  left?: Section
  right?: Section
  ctaButtonText?: string
}

const About = ({
  title,
  content,
  image,
  left,
  right,
  ctaButtonText,
}: Props) => (
  <div className="about-wrapper">
    <div className="container">
      <div className="row row--35">
        <div className="col-lg-5 col-md-12">
          <div className="thumbnail">
            <img className="w-100" src={image} alt="About Images" />
          </div>
        </div>

        <div className="col-lg-7 col-md-12">
          <div className="about-inner inner">
            <div className="section-title">
              <h2 className="title">{title}</h2>
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
            {left && image && (
              <div className="row mt--30 mt_sm--10">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="about-us-list">
                    <h3 className="title">{left.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: left.content }} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="about-us-list">
                    <h3 className="title">{right.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: right.content }} />
                  </div>
                </div>
              </div>
            )}

            {ctaButtonText && (
              <div className="row mt--30">
                <div className="col-12">
                  <Link to="/o-mnie" className="rn-button-style--2 btn-solid">
                    Czytaj więcej
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default About
