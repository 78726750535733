import * as React from "react"
import Slider from "react-slick"

const SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  adaptiveHeight: true,
}

type Testimonial = {
  node: {
    id: string
    frontmatter: {
      title: string
      fullName: string
      content: string
      image: string
    }
  }
}

type Props = {
  testimonials: Testimonial[]
}

const Testimonials = ({ testimonials }: Props) => (
  <div className="slider slider--testimonials">
    <Slider {...SLIDER_SETTINGS}>
      {testimonials.map(({ node }) => (
        <div className="rn-testimonial-content text-center" key={node.id}>
          {node.frontmatter.image && (
            <div className="testimonial__thumbnail">
              <img
                className="testimonial__thumbnail__image"
                src={node.frontmatter.image}
                alt={`Opinia ${node.frontmatter.fullName}`}
              />
            </div>
          )}
          <div className="container">
            <div className="inner">
              <p>{node.frontmatter.content}</p>
            </div>
            <div className="author-info">
              <h6>
                <span>{node.frontmatter.fullName} </span>{" "}
                {node.frontmatter.title && <>- {node.frontmatter.title}</>}
              </h6>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  </div>
)

export default Testimonials
