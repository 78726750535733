import * as React from "react"
import Slider from "react-slick"

const SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  autoplay: false,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 993,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

type Certificate = {
  image: string
}

type Props = {
  certificates: Certificate[]
}

const Certificates = ({ certificates }: Props) => (
  <div className="slider slider--certificates">
    <Slider {...SLIDER_SETTINGS}>
      {certificates.map(({ image }, index) => (
        <div key={index}>
          <img src={image} />
        </div>
      ))}
    </Slider>
  </div>
)

export default Certificates
